body.cms-home {
    h1 {
        display: none;
    }
    
    .std {
        //.widget-new-products {
            ul.products-grid {
                list-style: none;
                li {
                    margin-left: 0;
                }
            }
        //}
    }
}

.minor-post-list {
    border-bottom: 1px solid $c-border;
    margin-bottom: $gap * 2;
}
.minor-post-list-item {
    height: auto;
    overflow: auto;
    border-top: 1px solid $c-border;
    padding: 27px 0 17px 0;
}
.more-news-button {
    font-style: italic;
    
    &:after {
        content: "  \2192";
    }
}
.minor-post-list-date {
    position: relative;
    max-width: 4em;
    text-align: center;
    padding: 0.5em 0.5em 0.7em;
    float: left;
    margin-right: 1em;
    background-color: $c-secondary-light;
    color: $c-secondary-dark;
}