.skip-nav .icon {
    @extend .icon-sprite;
    background-position: 0px ($toolbar-icon-padding-offset/2);
}
.skip-cart  .icon {
    @extend .icon-sprite;
    background-position: -0px  (-100px + ($toolbar-icon-padding-offset/2) + 1px);
}
.skip-account .icon {
    @extend .icon-sprite;
    background-position: -0px  (-50px + ($toolbar-icon-padding-offset/2) + 2px);
}
.skip-search .icon {
    @extend .icon-sprite;
    background-position: 0px (-150px + ($toolbar-icon-padding-offset/2));
}


@include bp(min-width, $bp-medium + 1) {
    .skip-cart,
    .skip-account {
        width: auto;
        padding: 0 10px;
    }
    #header-cart.skip-active,
    #header-account.skip-active {
        @include menu;
        border-top: 0;
        padding: $gap;
        display: block;
        position: absolute;
        z-index: 200;
        top: 90px;
        right: 15px;
        width: 380px;
        background: white;
    }
    #header-account.skip-active {
        top: 0;
        right: 115px;
        width: 200px;
    }

    #header-account a {
        display: block;
        padding: 5px 10px;
        color: $c-text;
        line-height: 2;
    }
    #header-account a:hover {
        color: $c-action;
    }
}

#header-account {
    padding: 5px 0;
    
    a {
        position: relative;
        display: block;
        //padding: 0 10px;
        line-height: 33px;
        text-align: center;
    }
    
    a:hover {
        color: $c-action;
    }
}

/* -------------------------------------------- *
 * Skip Cart Notifier
 */

.skip-cart .count {
    @include inlineblockfix;
    //display: inline-block;
    position: relative;
    z-index: 5;
    width: 22px;
    height: 22px;
    background: $c-primary;
    color: white;
    //font-size: $f-size-xs;
    line-height: 22px;
    text-align: center;
}

@include bp(min-width, $bp-medium + 1) {
    .skip-cart {
        text-transform: uppercase;
        &:hover {
            text-decoration: none;
        }
    }
}

/* -------------------------------------------- *
 * Mini Cart - Empty
 */

.cart-menu .empty {
    padding: 20px;
    text-align: center;
}

/* -------------------------------------------- *
 * Mini Cart - Full
 */

/* Actions */

.cart-menu .actions {
    overflow: hidden;
    padding: 15px;
}

.cart-menu .actions .button {
    float: left;
    width: 48%;
}

.cart-menu .actions .button + .button {
    margin-left: 4%;
}

/* Subtotal */

.cart-menu .subtotal {
    padding: 0 15px 15px;
    font-size: 24px;
    line-height: 1.2;
}

/* List */

.mini-cart-list {
    margin-bottom: 5px;
}

.mini-cart-list li {
    position: relative;
    min-height: (15px + 60px + 15px);
    padding: 15px 15px 15px (15px + 60px + 15px);
    border-top: 1px solid $c-module-border;
    font-size: $f-size-s;
    line-height: 1.35;
}

.mini-cart-list .product-name {
    @include inlineblockfix;
    //display: inline-block;
}

.mini-cart-list .product-image {
    position: absolute;
    left: 15px;
    top: 15px;
}

.mini-cart-list .product-image img {
    width: 60px;
    height: 60px;
}

//.mini-cart-list .details { }

.mini-cart-list .has-options {
    margin-bottom: 0;
    color: $c-text-gray;
    font-size: $f-size-xs;
}

/* Too full - additional items will be shown in cart */
.cart-menu .last-added {
    padding: 10px 15px 15px;
    border-top: 1px solid $c-module-border;
    font-size: $f-size-s;
}

//-------

#header-search {
    padding-bottom: 15px;
}

#search {
    width: 100%;
    height: 40px;
    padding-right: 40px;
}

#search_mini_form .input-box {
    position: relative;
    padding-top: 0;
}

#search_mini_form .search-button {
    @include image-replacement;
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    border: 0;
    background: none;
    box-shadow: none;
}

#search_mini_form .search-button:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    @extend .icon-sprite;
    background-position: 0+4px (-150px + ($toolbar-icon-padding-offset/2));
}

#search_mini_form .search-button:hover:before {
    opacity: 0.8;
}

#search_mini_form .search-button:active:before {
    margin-top: -15px + 2px;
    margin-left: -15px + 2px;
}

@include bp(max-width, $bp-large) {
    #logo-and-skip-container {
        &:after {
            @include clearfix;
        }
    }
}

@include bp(min-width, $bp-large + 1) {
    #header-search {
        width: 180px;
        position: relative;
        float: right;
        top: 20px;
        right: 2*$gap;
        display: block;
        
        #search {
            height: 50px;
        }
        #search_mini_form .search-button {
            top: 6px;
            right: 6px;
        }
    }
    .skip-search {
        display: none;
    }
    #logo-and-skip-container {
        overflow: visible;
    }
}
@include bp(min-width, $bp-xlarge + 1) {
    #header-search {
        width: 300px;
    }
}