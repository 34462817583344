/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 16.9.2014, 7:04:46
    Author     : Miloslav
*/

@mixin bg-and-color-transition ($time: 0.3s){
        transition: background-color $time, color $time linear;
   -moz-transition: background-color $time, color $time linear;
-webkit-transition: background-color $time, color $time linear;
     -o-transition: background-color $time, color $time linear;
}

@mixin all-transition ($time: 0.3s){
        transition: all $time;
   -moz-transition: all $time;
-webkit-transition: all $time;
     -o-transition: all $time;
}