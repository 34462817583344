// =============================================
// Compass Defaults
// =============================================

// Border Radius
$default-border-radius: 5px;

// Transition
$default-transition-property: all;
$default-transition-duration: 200ms;
$default-transition-function: linear;
$default-transition-delay: 0;

// =============================================
// Primary Break Points
// =============================================

// If changed, they must be updated in app.js

$bp-xsmall: 479px;
$bp-small: 599px;
$bp-medium: 770px;
$bp-large: 979px;
$bp-xlarge: 1199px;

// =============================================
// Base Values
// =============================================

// Spacing
$icon-size: 30px;
$gap: 10px;
$trim: 30px;
$trim-small: 15px; // This will get applied on viewports smaller than 480px

// Padding that will get applied to content areas
$box-spacing-large: 20px;

// Standard padding around box elements such as banner messages, etc
$box-spacing: 10px;

// Standard spacing between elements within a box, such as "Add to cart", "Price box", etc
$element-spacing: 7px;

// Dimensions
$max-content-width: 1200px;
$max-container-width: $trim + $max-content-width + $trim;
$max-std-formatted-width: 50em;

// =============================================
// Brand Colors
// =============================================

// Usually not used directly in Sass partials.
// Only used to define context-based color vars in this file.

$c-primary: #e01e0a; //#D22B09; //#BF0C08;
$c-secondary: #D1D9E4;
$c-secondary-light: #E9EDF2;
$c-secondary-light2: lighten($c-secondary, 10%);
$c-secondary-dark: #5C676B;
$c-border: darken($c-secondary, 5%);

$c-blue: #3399CC;
$c-green: #00A55F;//#11B400;
$c-pink: #D85378;
$c-orange: #F3793B;
$c-red: #CF5050;
$c-yellow: #FFDD15;
$c-black: #000000;

// =============================================
// Base Colors
// =============================================

// Text

$c-text: #5C676B; //#636363;
$c-text-gray: #98a1a8;
$c-text-white: #E6E6E6;
$c-text-primary: $c-primary;


// Interaction

$c-action: $c-primary;
$c-stimulus: darken($c-primary, 15%);
$c-subtle: #A0A0A0;

// Notifications

$c-danger: #FF0000;
$c-success: $c-green;
$c-warn: #E26703;
$c-dark: #676157;

// Global Elements

$c-divider-border: $c-secondary-light2;

// =============================================
// Element Colors
// =============================================

// Buttons

$c-button: $c-primary;
$c-button-hover: darken($c-primary, 5%);
$c-button-active: darken($c-primary, 10%);

// Secondary Buttons

$c-button-secondary: #DDDDDD;
$c-button-secondary-hover: darken(#DDDDDD, 5%);
$c-button-secondary-active: darken(#DDDDDD, 10%);

$c-button-subtle: #ECECEC;

// Forms

$c-input-border: $c-border;
$c-input-border-focus: $c-primary;

$c-input-placeholder: $c-text-gray;
$c-input-text: $c-text;

$c-fieldset-border: $c-border;
$c-fieldset-border-light: $c-secondary;

// Headings

$c-h1: $c-primary;
$c-h2: $c-text;
$c-h3: $c-text;
$c-h4: $c-text;
$c-h5: $c-text;
$c-h6: $c-text;

// Link
$c-link: $c-action;
$c-link-hover: darken($c-link, 5%);
//$c-link-focus: $c-action;
//$c-link-active: darken($c-link, (5% / 2));

// Module (generic)

$c-module-background: $c-secondary-light2;
// This should be applied whenever the border is surrounding a white element and/or is directly showing on white.
$c-module-border: $c-border;
// This should be applied whenever the border is wrapping an element with $c-module-background
$c-module-border-light: $c-secondary-light2;
$c-module-border-highlight: $c-primary;

// Table

$c-table-background: $c-secondary-light2;
$c-table-border: $c-border;

$c-table-zebra-odd: white;
$c-table-zebra-even: $c-secondary-light2;

// Tabs

$c-tabs-background: $c-secondary;

// =============================================
// Typography
// =============================================

// Google Web Font stylesheet is included via layout XML

// Font Stacks

$f-stack-sans: 'Ubuntu', 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;
//$f-stack-serif: Georgia, Times, 'Times New Roman', serif;
$f-stack-special: 'Ubuntu', 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;

$f-stack-default: $f-stack-sans;

// Font Sizes

$f-size-xxl: 24px;
$f-size-xl: 20px;
$f-size-l: 18px;

$f-size: 16px;

$f-size-s: 14px;
$f-size-xs: 12px;
$f-size-xxs: 12px;

$f-weight: 400;
$f-weight-b: 500;

// Base Measures

$b-line-height: 1.5625em;
$b-margin-bottom: 0.75em;
